import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import './../i18n';
import {useNavigate , useLocation } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as bg } from "./../flag/bg.svg";
import {ReactComponent as de } from "./../flag/de.svg";
import {ReactComponent as el } from "./../flag/el.svg";
import {ReactComponent as es } from "./../flag/es.svg";
import {ReactComponent as fr } from "./../flag/fr.svg";
import {ReactComponent as it } from "./../flag/it.svg";
import {ReactComponent as pl } from "./../flag/pl.svg";
import {ReactComponent as pt } from "./../flag/pt.svg";
import {ReactComponent as ro } from "./../flag/ro.svg";
import {ReactComponent as en } from "./../flag/en.svg";
import Flag from "./Flag";

// nota orders count, see languagesCode order
const languages = [{ fr}, {de}, {en}, {el}, {bg}, {pl}, {ro}, {it}, {pt},{es}];


const SetLanguage = ({ reload }) => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();


    const dispatch = useDispatch();
    const lang = useSelector((state) => state.language);

    const location = useLocation();

    const getBrowserLanguage = () => {
        const lang =  navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;
        return lang.split('-')[0];
    }

    const [languagesWithFlag, setFlags ] = useState([]);



    useEffect( () => {
        if (reload === true) {
            const lang = getBrowserLanguage();
            console.log('change', lang)
            i18n.changeLanguage(lang).then(() => {
                navigate('/' + lang);

            });
        }
    // eslint-disable-next-line
    }, [reload]);

    useEffect(() => {
        const data = location.pathname.split('/');
        if(data[1]) {
            dispatch({type: 'SET_LANGUAGE', payload: data[1]});
        }
    }, [location, dispatch])


    const replaceLanguageInURL = (url, newLang) => {
        // Utiliser une expression régulière pour trouver la partie de la langue dans l'URL
        return url.replace(/^\/[a-z]{2}(\/|$)/, `/${newLang}$1`);
    }

    const onChange = (e) => {
        console.log(e.target.value);
        i18n.changeLanguage(e.target.value);
        const url = new URL(window.location.href);
        const newUrl = replaceLanguageInURL(url.pathname, e.target.value );
        navigate(newUrl);
        dispatch({type: 'SET_LANGUAGE', payload: e.target.value });

    }


    return (
        <div className="container-lang">
            <select value={lang} onChange={e => onChange(e)} className="form-control">
                {languages.filter((value) => process.env.REACT_APP_TYPE === 'b2c' ? ['fr', 'de'].includes(Object.keys(value)[0]) : true ).map((element, index ) => {
                    const lang = Object.keys(element)[0];
                    return (<option key={lang} value={lang}>{t(lang)}</option>);
                })};
            </select>
            <Flag lang={lang}></Flag>
        </div>

    );
}
export default SetLanguage;